/**
 * localStorage access_token key
 * @type {string}
 */
export const ACCESS_TOKEN = 'GlobalRegistryAccessToken'


export const APPLICATION_INIT = 'APPLICATION_INIT'

export const ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

export const UPDATE_TITLE = 'UPDATE_TITLE'

export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_DEFAULT = 'USER_LOGIN_DEFAULT'
export const USER_LOGIN_LOADING = 'USER_LOGIN_LOADING'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'

export const REQUEST_START = 'REQUEST_START'
export const REQUEST_END = 'REQUEST_END'
export const REQUEST_ERROR = 'REQUEST_ERROR'
export const REQUEST_CANCEL = 'REQUEST_CANCEL'

export const GET_ENTITY_TYPES = 'GET_ENTITY_TYPES'
export const GET_ENTITY_TYPE = 'GET_ENTITY_TYPE'
export const CREATE_ENTITY_TYPE = 'CREATE_ENTITY_TYPE'
export const UPDATE_ENTITY_TYPE = 'UPDATE_ENTITY_TYPE'

export const CREATE_RELATIONSHIP_TYPE = 'CREATE_RELATIONSHIP_TYPE'
export const UPDATE_RELATIONSHIP_TYPE = 'UPDATE_RELATIONSHIP_TYPE'

export const GET_MEASUREMENT_TYPES = 'GET_MEASUREMENT_TYPES'
export const CREATE_MEASUREMENT_TYPE = 'CREATE_MEASUREMENT_TYPE'
export const UPDATE_MEASUREMENT_TYPE = 'UPDATE_MEASUREMENT_TYPE'

export const GET_SYSTEMS = 'GET_SYSTEMS'
export const GET_SYSTEM = 'GET_SYSTEM'
export const CREATE_SYSTEM = 'CREATE_SYSTEM'
export const UPDATE_SYSTEM = 'UPDATE_SYSTEM'
export const DELETE_SYSTEM = 'DELETE_SYSTEM'
export const RESET_SYSTEM_ACCESS_TOKEN = 'RESET_SYSTEM_ACCESS_TOKEN'

export const GET_TRUST_RELATIONSHIPS = 'GET_TRUST_RELATIONSHIPS'
export const CREATE_TRUST_RELATIONSHIP = 'CREATE_TRUST_RELATIONSHIP'
export const DELETE_TRUST_RELATIONSHIP = 'DELETE_TRUST_RELATIONSHIP'

export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS'
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION'
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION'
